<template>
    <div>
        <div v-if="show">
            <div class="card" v-show="operation !== 'detail'">
                <div class="card-header card-header-flex pb-2 s-fixed-header">
                    <div class="w-100 mt-2">
                        <div class="row">
                            <div class="col-8">
                                <h5 class="mt-3 ml-0 mr-3 mb-2">
                                    <strong>
                                        <template v-if="operation === null">
                                            {{$t('title.spotOrders')}}
                                            <span v-if="pagination.total">({{pagination.total}})</span>
                                        </template>
                                        <template v-else>{{ $t(operationTitle) }}</template>
                                    </strong>
                                </h5>
                            </div>
                            <div class="col-4 text-right">
                                <div v-if="operation === null">
                                    <div class="mt-0">
                                        <b-input-group class="mt-3">
                                            <b-form-input type="search" class="form-control form-control-sm"
                                                          :placeholder="$t('input.whatAreYouLookingFor')"
                                                          v-on:keyup.enter="handleSearch"
                                                          v-model="search">
                                            </b-form-input>
                                            <b-input-group-append>
                                                <b-button @click="setOperation('add')" variant="info" size="sm"
                                                          :title="$t('button.title.addNewItem')"
                                                          v-if="$global.hasRole('efl') || $global.hasRole('superadmin')"
                                                          v-b-tooltip.hover>
                                                    <i class="fe fe-plus"></i> {{$t('button.addNew')}}
                                                </b-button>
                                                <b-button @click="handleSpotLoadAddClick" variant="secondary"
                                                          size="sm" :title="$t('button.title.createSpotLoad')"
                                                          :disabled="selectedSpotLoadLength <= 0"
                                                          v-if="$global.hasPermission('spotloadsstore')"
                                                          v-b-tooltip.hover>
                                                    <i class="fe fe-plus"></i> {{$t('button.createSpotLoad')}}
                                                    <b-badge variant="dark">{{selectedSpotLoadLength}}</b-badge>
                                                </b-button>
                                                <b-button size="sm" :title="$t('button.title.filterRecords')"
                                                          variant="outline-info"
                                                          @click="filters.visible = !filters.visible" v-b-tooltip.hover
                                                          v-if="$global.hasPermission('ordersview')">
                                                    <i class="fa fa-filter"></i>
                                                </b-button>
                                                <b-button size="sm" :title="$t('button.title.resetList')"
                                                          variant="outline-info"
                                                          @click="handleResetFilterClick()" v-b-tooltip.hover>
                                                    <i class="fa fa-refresh"></i>
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </div>
                                </div>
                                <div v-else>
                                    <b-button variant="warning" size="sm" class="mt-0"
                                              @click="handleOperationClose()"
                                              v-b-tooltip.hover :title="$t('button.title.cancel')">
                                        <i class="fa fa-arrow-left"></i> {{$t('button.cancel')}}
                                    </b-button>
                                </div>
                            </div>
                        </div><!-- /.row -->
                    </div><!-- /.w-100 -->
                </div>
                <div class="card-body">
                    <div class="orders-table">
                        <b-table hover responsive
                                 :busy="listingLoading"
                                 :items="dataSource"
                                 :fields="columns"
                                 :sort-by.sync="sortField"
                                 selectable
                                 ref="orderTable"
                                 @row-clicked="handleRowClick"
                                 @sort-changed="handleSortChange">
                            <template v-slot:cell(brand_id)="{ detailsShowing, item, index, rowSelected }">
                                <template v-if="rowSelected">
                                    <span aria-hidden="true">&check;</span>
                                </template>
                                <a @click="toggleDetails(item)" class="d-inline in-center">
                                    <i :class="[{'fa fa-plus': !detailsShowing}, {'fa fa-minus': detailsShowing}]"></i>
                                </a>
                                <a-avatar shape="square" :size="32" icon="car"
                                          :title="item.brand ? item.brand.title : ''"
                                          v-if="item.brand.logo && item.brand.logo.download_url"
                                          :src="item.brand.logo.system_url+'/thumbnail/32x32/'+item.brand.logo.name2"/>
                                <a-avatar shape="square" :size="32" icon="car"
                                          :title="item.brand ? item.brand.title : ''" v-else/>
                            </template>
                            <template v-slot:cell(client_id)="record">
                                {{ (record.item.client ? record.item.client.company_name : '') }}
                            </template>
                            <template v-slot:cell(load_status)="{item}">
                                <ol-status :status="item.load_status"></ol-status>
                            </template>
                            <template v-slot:cell(from_location_id)="{item}">
                                <print-ad titled="1" :item="item.to_location"
                                          v-if="item.load_status == 8 || item.load_status == 9"></print-ad>
                                <print-ad titled="1" :item="item.from_location" v-else></print-ad>
                            </template>
                            <template v-slot:cell(model)="record">
                                <div>{{record.item.model ? record.item.model.title : ""}}</div>
                                <div>{{record.item.model ? record.item.model.version_code : ""}}</div>
                            </template>
                            <template v-slot:cell(updated_at)="record">
                                {{$global.utcDateToLocalDate(record.item.updated_at) }}
                            </template>
                            <template v-slot:cell(action)="{item}">
                                <a @click="setOperation('edit', item.id)"
                                   :title="$t('button.title.editItem')"
                                   v-if="item.load_status <= 1 && item.is_manual === 1"
                                   v-b-tooltip.hover>
                                    <i class="fe fe-edit"></i>
                                </a>
                                <!--                                <a @click="setOperation('detail', record.item.id)"-->
                                <!--                                   :title="$t('button.title.detailItem')" v-if="$global.hasPermission('ordersview')"-->
                                <!--                                   class="ml-2"-->
                                <!--                                   v-b-tooltip.hover>-->
                                <!--                                    <i class="icmn-info"></i>-->
                                <!--                                </a>-->
                            </template>
                            <template v-slot:row-details="{ item }">
                                <b-card>
                                    <b-list-group flush>
                                        <b-list-group-item><strong>{{$t('column.orderCode')}}</strong>:
                                            {{item.order_id}}
                                        </b-list-group-item>
                                        <b-list-group-item><strong>{{$t('column.comments')}}</strong>:
                                            {{item.comments}}
                                        </b-list-group-item>
                                    </b-list-group>
                                </b-card>
                            </template>
                        </b-table><!-- /.b-table -->
                        <div class="clearfix">
                            <div class="float-left ">
                                <b-form-select v-model="pagination.perPage" :options="[5, 10, 50, 100, 1000]"
                                               size="sm"></b-form-select>
                            </div>
                            <div class="float-right">
                                <b-pagination
                                    v-model="pagination.current"
                                    :total-rows="pagination.total"
                                    :per-page="pagination.perPage"
                                    :first-text="$t('paginations.first')"
                                    :prev-text="$t('paginations.prev')"
                                    :next-text="$t('paginations.next')"
                                    :last-text="$t('paginations.last')"
                                ></b-pagination>
                            </div><!-- /.pull-right -->
                        </div><!-- /.clearfix -->
                    </div><!-- /.orders-table-->

                    <div class="orders-operation">
                        <a-drawer
                            placement="right"
                            :width="'950px'"
                            :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                            :closable="false"
                            @close="handleOperationClose"
                            :visible="operation !== null && operation !== 'detail'"
                            :zIndex="10"
                            :title="$t(operationTitle)"
                        >
                            <form @submit.prevent="handleSubmit" autocomplete="off">
                                <b-row>
                                    <b-col cols="12" v-show="global.pendingRequests > 0">
                                        <a-skeleton active :paragraph="{ rows: 5 }"/>
                                    </b-col>
                                    <b-col cols="12" v-show="global.pendingRequests <= 0">
                                        <b-row>
                                            <b-col sm="4">
                                                <div class="pull-right">
                                                    <quick-client-form
                                                        :after-create="handleAfterQuickClientCreated"
                                                        allow-create="1">
                                                    </quick-client-form>
                                                </div>
                                                <b-form-group
                                                    :label="$t('input.client')+' *'"
                                                    label-for="client_id"
                                                    :invalid-feedback="formErrors.first('client_id')">
                                                    <treeselect
                                                        :multiple="false"
                                                        :options="dropdowns.clients"
                                                        placeholder=""
                                                        v-model="formFields.client_id"
                                                        :class="[{'invalid is-invalid': (formErrors.has('client_id'))}]"
                                                    />
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                            <b-col sm="4">
                                                <div class="form-group">
                                                    <span class="pull-left">
                                                            <label for="brand"
                                                                   class="d-block">{{$t('input.brand')}} *</label>
                                                        </span><!-- /.pull-left -->
                                                    <span class="pull-right">
                                                        <quick-brand-form
                                                            allow-create="1"
                                                            :id="formFields.brand_id"
                                                            :after-create="handleAfterQuickBrandCreated">
                                                        </quick-brand-form>
                                                    </span><!-- /.pull-right -->
                                                </div>
                                                <treeselect
                                                    :multiple="false"
                                                    :options="dropdowns.brands"
                                                    placeholder=""
                                                    v-model="formFields.brand_id"
                                                    :class="[{'invalid is-invalid': (formErrors.has('brand_id'))}]"
                                                />
                                            </b-col><!--/b-col-->
                                            <b-col sm="4">
                                                <div class="clearfix">
                                                <span class="pull-left">
                                                    <label for="brand" class="d-block">{{$t('input.models')}} *</label>
                                                </span><!-- /.pull-left -->
                                                    <span class="pull-right">
                                                    <quick-model-form
                                                        :allow-create="(formFields.brand_id ? 1 : 0)"
                                                        :depend-brand-id="formFields.brand_id"
                                                        :after-create="handleAfterQuickModelCreated">
                                                    </quick-model-form>
                                                </span><!-- /.pull-right -->
                                                </div><!-- /.clearfix -->
                                                <treeselect
                                                    :multiple="false"
                                                    :options="_.filter(dropdowns.models, (item) => item.brand_id === formFields.brand_id)"
                                                    placeholder=""
                                                    v-model="formFields.model_id"
                                                    :class="[{'invalid is-invalid': (formErrors.has('model_id'))}]"
                                                />
                                            </b-col><!--/b-col-->
                                            <b-col sm="6">
                                                <div class="form-group">
                                                    <div class="clearfix">
                                                        <span class="pull-left">
                                                            <label for="from_location_id" class="d-block">
                                                                {{$t('input.fromLocation')}} *
                                                            </label>
                                                        </span><!-- /.pull-left -->
                                                        <span class="pull-right">
                                                            <quick-location-form
                                                                allow-update="1"
                                                                allow-create="1"
                                                                :id="formFields.from_location_id"
                                                                :after-create="handleAfterQuickFromLocationCreated"
                                                                :after-update="handleAfterQuickFromAndToLocationUpdated">
                                                            </quick-location-form>
                                                        </span><!-- /.pull-right -->
                                                    </div><!-- /.clearfix -->
                                                    <treeselect
                                                        :multiple="false"
                                                        :options="dropdowns.locations"
                                                        placeholder=""
                                                        v-model="formFields.from_location_id"
                                                        :class="[{'invalid is-invalid': (formErrors.has('from_location_id'))}]"
                                                    />
                                                </div>
                                            </b-col><!--/b-col-->
                                            <b-col sm="6">
                                                <div class="form-group">
                                                    <div class="clearfix">
                                                        <span class="pull-left">
                                                            <label for="to_location_id" class="d-block">
                                                                {{$t('input.toLocation')}} *
                                                            </label>
                                                        </span><!-- /.pull-left -->
                                                        <span class="pull-right">
                                                            <quick-location-form
                                                                allow-update="1"
                                                                allow-create="1"
                                                                :id="formFields.to_location_id"
                                                                :after-create="handleAfterQuickToLocationCreated"
                                                                :after-update="handleAfterQuickFromAndToLocationUpdated">
                                                            </quick-location-form>
                                                        </span><!-- /.pull-right -->
                                                    </div><!-- /.clearfix -->
                                                    <treeselect
                                                        :multiple="false"
                                                        :options="dropdowns.locations"
                                                        placeholder=""
                                                        v-model="formFields.to_location_id"
                                                        :class="[{'invalid is-invalid': (formErrors.has('to_location_id'))}]"
                                                    />
                                                </div>
                                            </b-col><!--/b-col-->
                                            <b-col sm="6">
                                                <b-form-group
                                                    :label="$t('input.orderId')+ ' *'"
                                                    label-for="orderId"
                                                    :invalid-feedback="formErrors.first('order_id')"
                                                >
                                                    <b-form-input
                                                        id="order_id"
                                                        v-model="formFields.order_id"
                                                        type="text"
                                                        :state="((formErrors.has('order_id') ? false : null))"
                                                        @focus="$event.target.select()"
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                            <b-col sm="6">
                                                <b-form-group
                                                    :label="$t('input.vinNumber')+ ' *'"
                                                    label-for="orderId"
                                                    :invalid-feedback="formErrors.first('vin_number')"
                                                >
                                                    <b-form-input
                                                        id="vin_number"
                                                        v-model="formFields.vin_number"
                                                        type="text"
                                                        :state="((formErrors.has('vin_number') ? false : null))"
                                                        @focus="$event.target.select()"
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                            <b-col sm="12">
                                                <b-form-group
                                                    :label="$t('input.comment')"
                                                    label-for="comments"
                                                    :invalid-feedback="formErrors.first('comments')"
                                                >
                                                    <b-form-textarea
                                                        id="comments"
                                                        v-model="formFields.comments"
                                                        :state="((formErrors.has('comments') ? false : null))"
                                                        @focus="$event.target.select()"
                                                    ></b-form-textarea>
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                        </b-row><!--/b-row-->
                                    </b-col><!--/b-col-->
                                    <div class="drawer-footer">
                                        <b-button
                                            size="sm"
                                            type="submit"
                                            variant="primary"
                                            :disabled="global.pendingRequests > 0"
                                            v-b-tooltip.hover :title="$t('button.title.save')"
                                        >
                                            <clip-loader style="display: inline" :loading="true" color="#fff"
                                                         size="12px"
                                                         v-if="global.pendingRequests > 0"></clip-loader>
                                            <i class="fa fa-save mr-1"></i>
                                            {{$t('button.save')}}
                                        </b-button>
                                        <b-button variant="warning" class="ml-3"
                                                  size="sm" @click="handleOperationClose()"
                                                  v-b-tooltip.hover :title="$t('button.title.cancel')">
                                            <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                                        </b-button>
                                    </div>
                                </b-row><!--/b-row-->
                            </form><!--/form-->
                        </a-drawer>
                    </div><!--/.orders-operation-->

                    <div class="filter-container">
                        <a-drawer
                            placement="left"
                            :width="'360px'"
                            :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                            :closable="false"
                            @close="filters.visible = !filters.visible"
                            :visible="!operation && filters.visible"
                            :zIndex="10"
                            :title="$t('title.advanceFilters')"
                        >
                            <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
                                <b-row>
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.brands')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.brands"
                                                placeholder=""
                                                v-model="filters.brands"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.models')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.models"
                                                placeholder=""
                                                v-model="filters.models"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group :label="$t('input.fromAddedDate')">
                                            <b-form-datepicker placeholder="" v-model="filters.from_date"
                                                               class="mb-2"></b-form-datepicker>
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group :label="$t('input.toAddedDate')">
                                            <b-form-datepicker placeholder="" v-model="filters.to_date"
                                                               class="mb-2"></b-form-datepicker>
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                </b-row>
                                <div class="drawer-footer">
                                    <b-button size='sm' variant="info" @click="filters.visible = !filters.visible"
                                              class="mr-2" :title="$t('button.title.closePanel')" v-b-tooltip.hover>
                                        {{$t('button.close')}}
                                    </b-button>
                                    <b-button size='sm' variant="warning" @click="handleResetFilterClick" class="mr-2"
                                              :title="$t('button.title.resetFilter')" v-b-tooltip.hover>
                                        {{$t('button.reset')}}
                                    </b-button>
                                    <b-button size='sm' variant="primary" button="submit" type="filled"
                                              :title="$t('button.title.filterRecords')" v-b-tooltip.hover>
                                        {{$t('button.apply')}}
                                    </b-button>
                                </div><!-- /.drawer-footer -->
                            </form>
                        </a-drawer>
                    </div><!-- /.filter-container -->
                </div>
            </div><!-- /.row -->
            <div v-if="operation === 'detail'">
                <!--                <detail :handle-close-operation="handleOperationClose"></detail>-->
            </div>
        </div>

        <!-- # Start Spot Inbound Load -->
        <a-drawer
            placement="right"
            :width="'86%'"
            :wrapStyle="{overflow: 'auto', paddingBottom: '108px'}"
            :closable="false"
            @close="handleSpotLoadFormOperationClose"
            :visible="spotLoadFormVisible"
            :zIndex="10"
            :title="$t('title.createSpotLoad')"
        >
            <form @submit.prevent="handleSpotLoadSubmit" autocomplete="off">
                <b-row v-if="spotLoadFormState.orders.length > 0">
                    <b-col sm="12" lg="4" md="4" class=" mb-sm-3">
                        <h4 class="d-sm-inline">
                            <span class="bg-gray-6 ml-2 rounded text-white p-1">
                                {{$t('title.load')}}#<strong>1</strong>
                            </span>
                        </h4>
                        <h4 class="mt-4 ml-2 d-sm-inline">
                            <span class="bg-light rounded p-1">
                                <small>{{$t('title.orders')}}-{{spotLoadFormState.loading_factors}}</small>
                            </span>
                        </h4>
                    </b-col>
                    <b-col sm="12" lg="12" md="12" class=" mb-sm-3">
                        <b-row>
                            <div class="col-sm-12">
                                <div class="row">
                                    <b-col sm="12" lg="4" md="4">
                                        <b-form-group
                                                :label="$t('input.deliverTo')+' *'"
                                                label-for="deliver_to"
                                                :invalid-feedback="formErrors.first('deliver_to')"
                                        >
                                            <treeselect
                                                :multiple="false"
                                                :options="dropdowns.deliveryTypes"
                                                :disabled="global.pendingRequests > 0"
                                                placeholder=""
                                                v-model="spotLoadFormState.deliver_to"
                                                :class="[{'invalid is-invalid': (formErrors.first('deliver_to'))}]"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col sm="12" lg="4" md="4" v-if="spotLoadFormState.deliver_to == 1">
                                        <b-form-group
                                                :label="$t('input.supplierCompound')+' *'"
                                                label-for="supplier_compound_id"
                                                :invalid-feedback="formErrors.first('supplier_compound_id')"
                                        >
                                            <treeselect
                                                :multiple="false"
                                                :options="dropdowns.supplierCompounds"
                                                placeholder=""
                                                v-model="spotLoadFormState.supplier_compound_id"
                                                @select="handleSupplierCompoundSelect"
                                                :class="[{'invalid is-invalid': (formErrors.first('supplier_compound_id'))}]"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col sm="12" lg="4" md="4" v-if="spotLoadFormState.deliver_to == 2">
                                        <b-form-group
                                            :label="$t('input.dealer')+' *'"
                                            label-for="dealer_id"
                                            :invalid-feedback="formErrors.first('dealer_id')"
                                        >
                                            <treeselect
                                                :multiple="false"
                                                :options="dropdowns.dealers"
                                                placeholder=""
                                                v-model="spotLoadFormState.dealer_id"
                                                @select="handleDealerSelect"
                                                :class="[{'invalid is-invalid': (formErrors.first('dealer_id'))}]"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </div>
                            </div>

                            <b-col sm="12" lg="4" md="4">
                                <div class="form-group">
                                    <div class="clearfix">
                                        <span class="pull-left">
                                            <label class="d-block">{{$t('input.supplierCarrier')}} *</label>
                                        </span><!-- /.pull-left -->
                                        <span class="pull-right">
                                            <quick-supplier-form
                                                allow-update="1"
                                                allow-create="1"
                                                :id="spotLoadFormState.supplier_carrier_id"
                                                :after-create="(inputs) => handleAfterSupplierCreate(inputs, index)"
                                                :after-update="(inputs) => handleAfterSupplierUpdate(inputs, index)">
                                            </quick-supplier-form>
                                        </span><!-- /.pull-right -->
                                    </div><!-- /.clearfix -->
                                    <treeselect
                                        :multiple="false"
                                        :options="dropdowns.supplierCarriers"
                                        placeholder=""
                                        required
                                        v-model="spotLoadFormState.supplier_carrier_id"
                                        :class="[{'invalid is-invalid': formErrors.has('supplier_carrier_id')}]"
                                    >
                                        <template slot="value-label" slot-scope="{ node }">
                                            <div :title="node.label">
                                                {{ node.label }}
                                            </div>
                                        </template>
                                    </treeselect>
                                    <div class="invalid-feedback">{{formErrors.first('supplier_carrier_id')}}</div>
                                </div><!-- /.form-group -->
                            </b-col>
                            <b-col sm="12" lg="4" md="4">
                                <div class="form-group">
                                    <b-form-group
                                        :label="$t('input.currency')+' *'"
                                        label-for="currency"
                                        :invalid-feedback="formErrors.first('currency')"
                                    >
                                        <treeselect
                                            required
                                            :multiple="false"
                                            :options="dropdowns.currencies"
                                            placeholder=""
                                            v-model="spotLoadFormState.spot_currency"
                                            :class="[{'invalid is-invalid': (formErrors.has('currency'))}]"
                                        />
                                    </b-form-group>
                                </div><!-- /.form-group -->
                            </b-col>
                            <b-col sm="12" lg="4" md="4">
                                <div class="form-group">
                                    <label class="d-block">{{$t('input.supervisor')}} *</label>
                                    <treeselect
                                        :multiple="false"
                                        :options="dropdowns.supervisors"
                                        placeholder=""
                                        v-model="spotLoadFormState.load_approver_id"
                                        :class="[{'invalid is-invalid': (formErrors.has('load_approver_id'))}]"
                                    />
                                    <div class="invalid-feedback">{{formErrors.first('load_approver_id')}}</div>
                                </div><!-- /.form-group -->
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col sm="12" lg="12" md="12" class=" mb-sm-3">
                        <table class="table table-sm table-striped">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th style="width:280px">{{$t('column.client')}}</th>
                                <th>{{$t('column.orderId')}}</th>
                                <th>{{$t('column.brand')}}/{{$t('column.model')}}</th>
                                <th style="width: 200px;">{{$t('column.fromLocation')}}</th>
                                <th style="width: 400px;">
                                    <div class="d-flex justify-content-between">
                                        <div>{{$t('column.toLocation')}}</div>
                                        <div>
                                            <quick-location-form
                                                allow-update="1"
                                                allow-create="1"
                                                :id="formFields.from_location_id"
                                                :after-create="handleAfterQuickLocationCreated"
                                                :after-update="handleAfterQuickFromAndToLocationUpdated">
                                            </quick-location-form>
                                        </div>
                                    </div>
                                </th>
                                <th style="width: 150px;">{{$t('column.price')}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(order, index) in spotLoadFormState.orders">
                                <td>{{index + 1}}</td>
                                <td>
                                    <div v-if="order.is_manual > 0">
                                        <treeselect
                                            :multiple="false"
                                            :options="dropdowns.clients"
                                            placeholder=""
                                            :clearable="false"
                                            v-model="order.client_id"
                                            :class="[{'invalid is-invalid': (formErrors.has('client_id'))}]"
                                        />
                                    </div>
                                    <div v-else>
                                        {{(order.client || {}).company_name}}
                                    </div>
                                </td>
                                <td>{{order.vin_number}}</td>
                                <td>
                                    <div>{{ _.find(dropdowns.brands, {id: order.brand_id}).label }}</div>
                                    <div>{{ _.find(dropdowns.models, {id: order.model_id}).label }}</div>
                                </td>
                                <td>
                                    {{_.find(dropdowns.locations, {id: order.from_location_id}).label}}
                                </td>
                                <td style="width: 250px;">
                                    <div class="form-group mb-0">
                                        <treeselect
                                            :multiple="false"
                                            :options="dropdowns.locations"
                                            placeholder=""
                                            required
                                            v-model="spotLoadFormState.orders[index].to_location_id"
                                        >
                                            <template slot="value-label" slot-scope="{ node }">
                                                <div :title="node.label">
                                                    {{ node.label }}
                                                </div>
                                            </template>
                                        </treeselect>
                                    </div><!-- /.form-group -->
                                    <small class="text-danger m-0 p-0" v-if="spotLoadFormState.orders[index].errors">{{spotLoadFormState.orders[index].errors}}</small>
                                </td>
                                <td>
                                    <b-form-group
                                        label-for="price"
                                        required
                                        :invalid-feedback="formErrors.first('price')"
                                        @focus="$event.target.select()"
                                    >
                                        <b-form-input v-model="spotLoadFormState.orders[index].spot_price"
                                                      type="number"
                                                      :state="((formErrors.has('price') ? false : null))"
                                        ></b-form-input>
                                    </b-form-group>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </b-col>
                    <b-col lg="12" md="12" sm="12" class="mt-3">
                        <b-form-group
                            :label="$t('input.comment')"
                            label-for="comments"
                            :invalid-feedback="formErrors.first('comments')"
                        >
                            <b-form-textarea
                                id="comments"
                                v-model="spotLoadFormState.comments"
                                :state="((formErrors.has('comments') ? false : null))"
                            ></b-form-textarea>
                        </b-form-group>
                    </b-col>
                    <b-col lg="12" md="12" sm="12" class="mb-5">
                        <b-card class="mb-0">
                            <b-card-header v-b-toggle="'spot-load-documents'" class="p-0">
                                <h4 class="mb-0">
                                    <span class="badge badge-primary">
                                        {{$t('title.attachments')}}
                                    </span>
                                    <small v-show="spotLoadFormState.documents.length > 0">
                                        - {{spotLoadFormState.documents.length}} Item/s
                                    </small>
                                </h4>
                            </b-card-header><!-- /.p-0-->
                            <b-collapse id="spot-load-documents">
                                <div class="bg-light p-3">
                                    <b-row>
                                        <b-col lg="4" md="4" sm="12">
                                            <div class="form-group">
                                                <b-form-group
                                                    :label="$t('input.title')+' *'"
                                                    label-for="title"
                                                    :invalid-feedback="formErrors.first('title')"
                                                >
                                                    <b-form-input
                                                        id="title"
                                                        v-model="spotLoadFormState.document.title"
                                                        type="text"
                                                        :state="((formErrors.has('title') ? false : null))"
                                                        @focus="$event.target.select()"
                                                    ></b-form-input>
                                                </b-form-group>
                                                <div class="invalid-feedback">{{formErrors.first('title')}}</div>
                                            </div><!-- /.form-group -->
                                        </b-col><!--/b-col-->
                                        <b-col lg="4" md="4" sm="12">
                                            <b-form-group class="mt-4 pt-2">
                                                <upload
                                                    v-model="spotLoadFormState.document.document"
                                                    :disabled="spotLoadFormState.document.document"
                                                    :title="(spotLoadFormState.document.document ? $t('msc.uploadedFile') : $t('msc.uploadFile'))"
                                                    css-class="mt-0 btn-sm"></upload>
                                                <b-button :title="$t('msc.removeUpload')"
                                                          type="button"
                                                          variant="outline-primary"
                                                          v-b-tooltip.hover
                                                          class="ml-2 ml-2 btn-sm"
                                                          @click="() => {spotLoadFormState.document.document = null;}"
                                                          :disabled="!spotLoadFormState.document.document"
                                                          v-if="spotLoadFormState.document.document">
                                                    <i class="fa fa-close"></i>
                                                </b-button>
                                                <b-button :title="$t('button.download')"
                                                          v-b-tooltip.hover
                                                          type="button"
                                                          variant="outline-primary"
                                                          class="ml-2 ml-2 btn-sm"
                                                          v-if="spotLoadFormState.document.document && spotLoadFormState.document.document.download_url"
                                                          :disabled="!(spotLoadFormState.document.document && spotLoadFormState.document.document.download_url)"
                                                          :href="(spotLoadFormState.document.document ? spotLoadFormState.document.document.download_url : '')"
                                                          target="_blank">
                                                    <i class="fa fa-cloud-download"></i>
                                                </b-button>
                                            </b-form-group>
                                            <div class="invalid-feedback d-block">
                                                {{formErrors.first('document')}}
                                            </div>
                                        </b-col><!--/b-col-->
                                        <b-col lg="2" md="2" sm="12">
                                            <div class="form-group">
                                                <label class="d-block">
                                                    <pre> </pre>
                                                </label>
                                                <b-button variant="info" type="button" size="sm"
                                                          class="btn-block"
                                                          @click="handleAddUpdateSpotLoadDocumentClick()">
                                                    <i class="fa fa-plus"></i>
                                                </b-button><!--/b-button-->
                                            </div><!-- /.form-group -->
                                        </b-col><!--/b-col-->
                                        <b-col lg="2" md="2" sm="12">
                                            <div class="form-group">
                                                <label class="d-block">
                                                    <pre> </pre>
                                                </label>
                                                <b-button variant="warning" type="button" size="sm"
                                                          class="btn-block"
                                                          @click="resetSpotLoadDocument()">
                                                    {{$t('button.reset')}}
                                                </b-button><!--/b-button-->
                                            </div><!-- /.form-group -->
                                        </b-col><!--/b-col-->
                                    </b-row><!--/b-row-->
                                    <b-row>
                                        <b-col cols="12">
                                            <table class="table table-bordered bg-white">
                                                <thead>
                                                <tr>
                                                    <th width="50">#</th>
                                                    <th width="180">{{$t('column.title')}}</th>
                                                    <th width="60">{{$t('column.action')}}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="(ct, I) in spotLoadFormState.documents"
                                                    :class="[{'table-primary': ct.token === spotLoadFormState.document.token}]">
                                                    <td>{{I + 1}}</td>
                                                    <td>
                                                        {{ct.title}}
                                                    </td>
                                                    <td>
                                                        <a @click="handleEditSpotLoadDocumentClick(ct.token)"
                                                           :title="$t('button.title.editItem')"
                                                           v-b-tooltip.hover>
                                                            <i class="fe fe-edit"></i>
                                                        </a>
                                                        <a :title="$t('button.download')" class=" ml-1"
                                                           :href="ct.document.download_url"
                                                           target="_blank" v-b-tooltip.hover>
                                                            <i class="fa fa-cloud-download"></i>
                                                        </a>
                                                        <a-popconfirm title="Are you sure？"
                                                                      @confirm="handleDeleteSpotLoadDocumentClick(ct.token)">
                                                            <i slot="icon" class="fe fe-trash"></i>
                                                            <a class=" ml-1"
                                                               :title="$t('button.title.deleteItem')"
                                                               v-b-tooltip.hover>
                                                                <i class="fe fe-trash"></i>
                                                            </a>
                                                        </a-popconfirm>
                                                    </td>
                                                </tr>
                                                </tbody>
                                                <tfoot v-show="spotLoadFormState.documents.length <= 0">
                                                <tr>
                                                    <th colspan="3">{{$t('title.noDataAvailable')}}</th>
                                                </tr>
                                                </tfoot>
                                            </table><!-- /.table table-bordered -->
                                        </b-col><!--/b-col-->
                                    </b-row><!--/b-row-->
                                </div><!-- /.bg-light -->
                            </b-collapse><!-- /#spot-load-documents-->
                        </b-card><!-- /b-card -->
                    </b-col><!--/b-col-->
                </b-row><!--/.row-->
                <div class="drawer-footer">
                    <a-popconfirm title="Are you sure？" @confirm="handleSpotLoadSubmit()">
                        <a class="btn btn-sm btn-primary"
                           :disabled="global.pendingRequests > 0">
                            <clip-loader style="display: inline" :loading="true" color="#fff"
                                         size="12px" v-if="global.pendingRequests > 0"></clip-loader>
                            <i class="fa fa-save mr-1"></i>
                            {{$t('button.save')}}
                        </a>
                    </a-popconfirm>

                    <b-button variant="warning" class="ml-3"
                              size="sm" @click="handleSpotLoadFormOperationClose()"
                              v-b-tooltip.hover :title="$t('button.title.cancel')">
                        <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                    </b-button>
                </div><!--/.drawer-footer-->
            </form><!--/form-->
        </a-drawer><!--/.drawer-->
    </div><!--/div-->
</template>
<script>
    import Error from '../../../util/Error'
    import {mapState} from 'vuex'
    import Datepicker from 'vuejs-datepicker'
    import {request} from '../../../util/Request'
    import Treeselect from '@riophae/vue-treeselect'
    import CreateSpotMixin from "../CreateSpotMixin"
    import QuickLocationForm from "../../locations/QuickLocationForm"
    import QuickSupplierForm from "../../suppliers/QuickSupplierForm"
    import moment from "moment-timezone"
    import ListingMixin from "../../../util/ListingMixin"
    // import Detail from './detail'
    import QuickClientForm from "../../clients/QuickClientForm";
    import QuickModelForm from "../../models/QuickModelForm";
    import QuickBrandForm from "../../brands/QuickBrandForm";

    const FORM_STATE = {
        client_id: null,
        brand_id: null,
        model_id: null,
        from_location_id: null,
        to_location_id: null,
        dealer_id: null,
        order_id: null,
        vin_number: null,
        comments: null,
        status: null,
        _method: 'post',
        is_manual: 1,
    };

    const FILTER_STATE = {
        visible: false,
        from_date: null,
        to_date: null,
        brands: [],
        models: [],
        status: [1, 8, 9],
        has_manufacturer_invoice_date: 1,
        manufacturer_invoice_date: null,
    };

    const COLUMN_DEFINITION = (self) => [
        {
            label: self.$t('column.brand'),
            key: 'brand_id',
            sortable: false,
            stickyColumn: true,
            class: 'w-120'
        },
        {
            label: self.$t('column.loadingPoint'),
            key: 'from_location_id',
            sortable: true,
            class: 'w-220'
        },
        {
            label: self.$t('column.vin'),
            key: 'vin_number',
            sortable: true,
        },
        {
            label: self.$t('column.model'),
            key: 'model',
            sortable: true,
        },
        {
            label: self.$t('column.status'),
            key: 'load_status',
            sortable: true,
        },
        {
            label: self.$t('column.modifiedAt'),
            key: 'updated_at',
            sortable: true,
        },
        (self.$global.hasAnyPermission(['ordersupdate'])
            ? {
                label: self.$t('column.action'),
                class: 'text-right',
                key: 'action',
                width: 150,
            } : {}),
    ];

    export default {
        mixins: [ListingMixin, CreateSpotMixin],
        components: {
            Treeselect,
            QuickLocationForm,
            QuickSupplierForm,
            QuickClientForm,
            QuickModelForm,
            QuickBrandForm,
            // Detail,
        },
        data() {
            return {
                operationTitle: 'title.orders',
                formFields: {...FORM_STATE},
                formErrors: new Error({}),
                filters: {...FILTER_STATE},
                listUrl: 'orders',
                columns: COLUMN_DEFINITION(this),
                dropdowns: {
                    brands: [],
                    models: [],
                    supplierCarriers: [],
                    currencies: [],
                    locations: [],
                    supervisors: [],
                    deliveryTypes: [
                        {id: 1, label: this.$t('title.deliverToCompound')},
                        {id: 2, label: this.$t('title.deliverToDealer')},
                        {id: 3, label: this.$t('title.default')},
                    ],
                    status: [
                        {id: -3, label: this.$t('title.pending')},
                        {id: -2, label: this.$t('title.qualityHoldBlocked')},
                        {id: -1, label: this.$t('title.confirmationOrder')},
                        {id: 1, label: this.$t('title.produced')},
                        {id: 2, label: this.$t('title.addedToLoad')},
                        {id: 3, label: this.$t('title.assignedToLoad')},
                        {id: 4, label: this.$t('title.loaded')},
                        {id: 5, label: this.$t('title.inTransit')},
                        {id: 6, label: this.$t('title.unloadRequested')},
                        {id: 7, label: this.$t('title.unloaded')},
                        {id: 8, label: this.$t('title.stocked')},
                        {id: 9, label: this.$t('title.hold')},
                        {id: 10, label: this.$t('title.transportOrder')},
                        {id: 11, label: this.$t('title.announced')},
                        {id: 12, label: this.$t('title.rejected')},
                        {id: 13, label: this.$t('title.confirmed')},
                        {id: 14, label: this.$t('title.pickUp')},
                        {id: 15, label: this.$t('title.stockExitInTransit')},
                        {id: 16, label: this.$t('title.deliveryRequested')},
                        {id: 17, label: this.$t('title.delivered')},
                    ],
                    priorities: [
                        {id: 1, label: this.$t('title.high')},
                        {id: 2, label: this.$t('title.medium')},
                        {id: 3, label: this.$t('title.low')}
                    ],
                },
                show: true,
                spotLoadFormVisible: false,
                spotLoadSelectedOrders: [],
                selectedSpotLoadLength: 0,
            }
        },
        mounted() {
            this.getBrands();
            this.getModels();
            this.getClients();
            this.getLocations();
            this.getCurrencies();
            this.getSupplierCarriers();
            this.getSupplierCompounds();
            this.getDealers();
            this.getSupervisors();
            this.$title = this.$t('topBar.navigations.modules.orders')
        },
        methods: {
            randomString(length, chars) {
                let result = '';
                for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
                return result;
            },
            toggleDetails(row) {
                this.$set(row, '_showDetails', ((row._showDetails) ? !row._showDetails : true))
            },
            handleRowClick(item, rowIndex, event) {
                this.handleSpotLoadRowSelection(item, rowIndex, event);
            },
            setOperation(operation = 'add', operationToken = null) {
                this.operationTitle = (operation === 'add' ? 'title.addOrder' : 'title.editOrder')
                this.operation = operation
                this.oToken = operationToken

                if (operation === 'edit') {
                    this.handleEditClick(operationToken)
                } else {
                    this.formFields.order_id = moment().format('MMYY') + this.randomString(2, 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ') + this.randomString(2, '0123456789')
                }
            },
            async handleSubmit() {
                this.formErrors = new Error({})
                try {
                    const response = await request({
                        url: this.formFields.id ? 'orders/update' : 'orders/create',
                        method: 'post',
                        data: {...this.formFields, manufacturer_invoice_date: moment().format('YYYY-MM-DD')},
                    })

                    if (this.formFields.id) {
                        this.itemUpdated()
                    } else {
                        this.itemAdded()
                    }

                    this.handleOperationClose()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                        return false
                    }

                    this.handleServerError(error)
                }
            },
            async handleDeleteClick(id) {
                try {
                    const response = await request({
                        method: 'post',
                        url: '/orders/delete',
                        data: {
                            id: id,
                        },
                    })
                    this.loadList(this.listQueryParams)
                    this.itemDeleted()
                } catch (errors) {
                    this.itemDeleteFails()
                }
            },
            async handleEditClick(id) {
                try {
                    const response = await request({
                        method: 'get',
                        url: `/orders/detail/${id}`,
                    })
                    this.operationTitle = this.$t('title.editOrder')
                    const {data} = response
                    const {client, brand, model, from_location, to_location} = data
                    this.formFields = {
                        client_id: (client ? client.id : null),
                        brand_id: (brand ? brand.id : null),
                        model_id: (model ? model.id : null),
                        to_location_id: (to_location ? to_location.id : null),
                        from_location_id: (from_location ? from_location.id : null),
                        order_id: data.order_id,
                        vin_number: data.vin_number,
                        comments: null,
                        _method: 'post',
                        id: data.id,
                        is_manual: 1
                    }
                } catch (e) {
                    this.itemEditFails()
                    this.formFields = {...FORM_STATE}
                }
            },
            async getBrands() {
                try {
                    const response = await request({
                        url: '/dropdowns/brands',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.brands = data

                } catch (e) {
                    this.dropdowns.brands = []
                }
            },
            async getModels() {
                try {
                    const response = await request({
                        url: '/dropdowns/models',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.models = data.map(item => ({...item, label: `${item.label} ${item.version_code}`}))
                } catch (e) {
                    this.dropdowns.models = []
                }
            },
            async getClients() {
                try {
                    const response = await request({
                        url: '/dropdowns/clients',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.clients = data.map(item => ({id: item.id, label: `${item.label}`}))

                } catch (e) {
                    this.dropdowns.clients = []
                }
            },
            async getLocations() {
                try {
                    const response = await request({
                        url: '/dropdowns/locations',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.locations = data.map(item => ({id: item.id, label: `${item.label}`}))
                } catch (e) {
                    this.dropdowns.locations = []
                }
            },
            async getCurrencies() {
                try {
                    const response = await request({
                        url: '/dropdowns/currencies',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.currencies = data
                } catch (e) {
                    this.dropdowns.currencies = []
                }
            },
            async getSupplierCarriers() {
                try {
                    const response = await request({
                        url: '/dropdowns/suppliers/carrier',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.supplierCarriers = data
                } catch (e) {
                    this.dropdowns.supplierCarriers = []
                }
            },
            async getSupervisors() {
                try {
                    const response = await request({
                        url: '/dropdowns/supervisors',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.supervisors = data

                } catch (e) {
                    this.dropdowns.supervisors = []
                }
            },
            async getSupplierCompounds() {
                this.dropdowns.supplierCompounds = [];

                try {
                    const response = await request({
                        url: 'dropdowns/suppliers/compound',
                        method: 'POST',
                        data: {
                            with_main_location: 1
                        }
                    })

                    const {data} = response
                    this.dropdowns.supplierCompounds = data
                } catch (e) {

                }
            },
            async getDealers() {
                this.dropdowns.dealers = [];

                try {
                    const response = await request({
                        url: 'dropdowns/dealers',
                        method: 'POST',
                    })

                    const {data} = response
                    this.dropdowns.dealers = data.map((item) => ({...item, label: `${item.dealer_id} - ${item.label}`}))
                } catch (e) {

                }
            },
            handleAfterQuickFromLocationCreated(inputs) {
                const newItem = {
                    id: inputs.id,
                    label: inputs.label
                }

                this.dropdowns.locations.push(newItem)
                this.formFields.from_location_id = inputs.id
            },
            handleAfterQuickToLocationCreated(inputs) {
                this.dropdowns.locations.push({
                    id: inputs.id,
                    label: inputs.label
                })
                this.formFields.to_location_id = inputs.id
            },
            handleAfterQuickFromAndToLocationUpdated(inputs) {
                const index = _.findIndex(this.dropdowns.locations, {id: inputs.id})
                this.$set(this.dropdowns.locations[index], 'label', inputs.label)
            },
            handleAfterQuickLocationCreated(inputs) {
                this.dropdowns.locations.push({
                    id: inputs.id,
                    label: inputs.label
                })
            },
            hasListAccess() {
                return this.$global.hasPermission('ordersview')
            },
            refreshList() {
                this.loadList()
                this.$emit('refresh')
            },
            getExtraParams() {
                return {
                    filters: {
                        ...this.filters,
                        from_date: ((this.filters.from_date) ? this.$global.dateToUtcDate(this.filters.from_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                        to_date: ((this.filters.to_date) ? this.$global.dateToUtcDate(this.filters.to_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                    },
                }
            },
            handleResetFilterClick() {
                this.filters = {...FILTER_STATE}
                this.isFilterApplied = 'reset'
                this.handleResetClick()
                this.loadList(this.listQueryParams)
            },
            afterCloseOperation() {
                this.formFields = {...FORM_STATE}
                this.refreshList()
            },
            handleAfterSupplierCreate(inputs, index) {
                const supplierCarriers = [...this.dropdowns.supplierCarriers, {id: inputs.id, label: inputs.name}];
                this.$set(this.dropdowns, 'supplierCarriers', supplierCarriers)
                this.spotLoadFormState.supplier_carrier_id = inputs.id;
            },
            handleAfterSupplierUpdate(inputs, index) {
                const supplierCarriers = [...this.dropdowns.supplierCarriers, {id: inputs.id, label: inputs.name}];
                this.$set(this.dropdowns, 'supplierCarriers', supplierCarriers)
                this.spotLoadFormState.supplier_carrier_id = inputs.id;
            },
            handleAfterQuickClientCreated(inputs) {
                const clients = [...this.dropdowns.clients, {id: inputs.id, label: inputs.company_name}];
                this.$set(this.dropdowns, 'clients', clients)
                this.formFields.client_id = inputs.id;
            },
            handleAfterQuickModelCreated(inputs) {
                const models = [...this.dropdowns.models, {
                    id: inputs.id,
                    label: inputs.title,
                    is_active: inputs.is_active,
                    brand_id: this.formFields.brand_id
                }];
                this.$set(this.dropdowns, 'models', models)
                this.dropdowns.models.push(inputs.id)
                this.formFields.model_id = inputs.id
            },
            handleAfterQuickBrandCreated(inputs) {
                const {id, title} = inputs
                this.dropdowns.brands.push({id: id, label: title})
                this.formFields.brand_id = id
            },
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }
</script>
<style lang="scss">
    .w-80 {
        width: 80px;
    }

    .w-120 {
        width: 120px;
    }

    .w-220 {
        width: 220px;
    }

    .in-center {
        position: relative;
        top: 1px;
        margin-right: 8px;
    }

    @media screen and (max-width: 1379px) {
        .ant-drawer-content-wrapper {
            width: 98% !important;
        }
    }
</style>
