import {request} from "../../util/Request";
import Error from "../../util/Error";

const SPOT_INBOUND_LOAD_DOCUMENT_STATE = {
    token: null,
    id: null,
    document: null,
    title: null,
    error: false
};

const DEFAULT_SPOT_INBOUND_ORDER_STATE = {
    order_id: null,
    from_location_id: null,
    client_id: null,
    old_client_id: null,
    client: null,
    brand_id: null,
    model_id: null,
    to_location_id: null,
    spot_price: null,
    vin_number: null,
    is_manual: 0,
    document: {...SPOT_INBOUND_LOAD_DOCUMENT_STATE},
    documents: [],
    ids: [],
    errors: null,
    comments: null,
}

const DEFAULT_FORM_STATE = {
    orders: [], // DEFAULT_SPOT_INBOUND_ORDER_STATE
    supplier_carrier_id: null,
    supplier_compound_id: null,
    dealer_id: null,
    deliver_to: 3,
    load_approver_id: null,
    loading_factors: 0,
    spot_currency: null,
    document: {...SPOT_INBOUND_LOAD_DOCUMENT_STATE},
    documents: [],
    comments: null,
}

export default {
    data() {
        return {
            spotLoadSelectedOrders: [],
            selectedSpotLoadLength: 0,
            spotLoadFormState: {...DEFAULT_FORM_STATE},
            dropdowns: {
                carrierSuppliers: [],
                currencies: [],
                supplierCompounds: [],
                dealers: [],
            },
        }
    },
    methods: {
        handleSpotLoadAddClick() {
            this.spotLoadFormState.orders = []
            const self = this
            self.spotLoadFormState.loading_factors = self.selectedSpotLoadLength
            _.map(self.spotLoadSelectedOrders, (item, index) => {
                let from_location = (item.load_status == 8 || item.load_status == 9) ? item.to_location.id : item.from_location.id;
                let to_location = (item.load_status == 8 || item.load_status == 9) ? null : item.to_location.id;

                self.spotLoadFormState.orders.push({
                    order_id: item.id,
                    from_location_id: from_location,
                    client_id: item.client.id,
                    old_client_id: item.client.id,
                    client: item.client,
                    brand_id: item.brand.id,
                    model_id: item.model.id,
                    to_location_id: to_location,
                    spot_price: 0,
                    vin_number: item.vin_number,
                    is_manual: item.is_manual,
                    deliver_to: null,
                    supplier_compound_id: null,
                    dealer_id: null,
                    errors: null
                })
            })

            this.spotLoadFormVisible = true
        },
        async handleSpotLoadSubmit() {
            if(this.validateSpotLoad())
                return;

            try {
                const response = await request({
                    url: 'spot/loads/create',
                    method: 'post',
                    data: {...this.spotLoadFormState},
                })

                this.loadCreateSuccess()
                this.handleSpotLoadFormOperationClose()
            } catch (error) {
                if (error.request && error.request.status && error.request.status === 422) {
                    this.validationError()
                    return false
                }

                this.handleServerError(error)
            }
        },
        validateSpotLoad() {
            let errors = false
            const self = this
            const formErrors = {}
            if(!this.spotLoadFormState.supplier_carrier_id) {
                formErrors.supplier_carrier_id = [this.$t('validation.required')]
            }

            if(!this.spotLoadFormState.load_approver_id) {
                formErrors.load_approver_id = [this.$t('validation.required')]
            }

            if(!this.spotLoadFormState.spot_currency) {
                formErrors.currency = [this.$t('validation.required')]
            }

            _.map(this.spotLoadFormState.orders, (item, index) => {
                self.spotLoadFormState.orders[index].errors = '';
                if(!item.client_id || !item.to_location_id || (!item.spot_price || item.spot_price <= 0)) {
                    errors = true
                    self.spotLoadFormState.orders[index].errors = self.$t('msc.requiredFieldsMissing')
                }
            })

            this.formErrors = new Error(formErrors)
            return errors;
        },
        handleSpotLoadRowSelection(item, rowIndex, event) {
            let response = true;

            if (item.order_id !== null && item.vin_number !== null && ((item.load_status == 1 && !item.added_in_load_at && item.is_manual == 1) || (item.is_manual == 0 && (item.load_status == 8 || item.load_status == 9 || item.load_status == 1)))) {
                const index = _.findIndex(this.spotLoadSelectedOrders, {id: item.id});
                if (index >= 0) { // if already exist then delete
                    setTimeout(() => this.$refs.orderTable.unselectRow(rowIndex), 200)
                    delete this.spotLoadSelectedOrders[index]
                    this.selectedSpotLoadLength = this.selectedSpotLoadLength - 1
                } else { // else add new
                    this.spotLoadSelectedOrders.push(item)
                    setTimeout(() => this.$refs.orderTable.selectRow(rowIndex), 200)
                    this.selectedSpotLoadLength = this.selectedSpotLoadLength + 1
                }
            } else {
                response = false
            }

            this.spotLoadSelectedOrders = _.compact(this.spotLoadSelectedOrders)
            return response;
        },
        handleSpotLoadFormOperationClose(refreshList = true) {
            this.spotLoadFormState = {...DEFAULT_FORM_STATE}
            this.spotLoadFormState.orders = []
            this.spotLoadFormVisible = false
            this.spotLoadSelectedOrders.length = 0
            this.selectedSpotLoadLength = 0
            this.$refs.orderTable.clearSelected()
            this.formErrors = new Error({})

            if(refreshList) { // to handle call from refreshList (When page change)
                this.refreshList()
            }
        },
        handleAddUpdateSpotLoadDocumentClick() {
            this.spotLoadFormState.document.error = false;
            this.formErrors = new Error({})
            let errors = {};

            if (!this.spotLoadFormState.document.document || this.spotLoadFormState.document.document.length <= 0 || !this.spotLoadFormState.document.document.id) {
                this.spotLoadFormState.document.error = true;
                errors.document = [this.$t('validation.required')];
            }

            if (!this.spotLoadFormState.document.title || _.trim(this.spotLoadFormState.document.title).length <= 2) {
                this.spotLoadFormState.document.error = true;
                errors.title = [this.$t('validation.required')];
            }

            _.map(this.spotLoadFormState.documents, (document) => {
                if(document.token !== this.spotLoadFormState.document.token) {
                    if(document.title === this.spotLoadFormState.document.title) {
                        errors.title = [this.$t('validation.duplicate')];
                        this.spotLoadFormState.document.error = true;
                        this.formErrors = new Error(errors);
                        return false;
                    }
                }
            });

            this.formErrors = new Error(errors)
            if(this.spotLoadFormState.document.error) return false;

            const entity = this.spotLoadFormState.document;
            let index = -1;
            if (entity.token)
                index = _.findIndex(this.spotLoadFormState.documents, {token: entity.token});

            if (this.spotLoadFormState.documents[index] !== undefined) {
                this.spotLoadFormState.documents[index] = entity;
            } else {
                entity.token = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                this.spotLoadFormState.documents.push(entity)
            }

            this.spotLoadFormState.document = {...SPOT_INBOUND_LOAD_DOCUMENT_STATE, token: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}
        },
        handleDeleteSpotLoadDocumentClick(token) {
            const index = _.findIndex(this.spotLoadFormState.documents, {token: token})
            if (this.spotLoadFormState.documents[index] !== undefined) {
                this.spotLoadFormState.documents.splice(index, 1);
            }
        },
        handleEditSpotLoadDocumentClick(token) {
            const index = _.findIndex(this.spotLoadFormState.orders.documents, {token: token})
            if (this.spotLoadFormState.documents[index] !== undefined) {
                this.spotLoadFormState.document = {...this.spotLoadFormState.documents[index]};
            }
        },
        resetSpotLoadDocument() {
            this.spotLoadFormState.document = {...SPOT_INBOUND_LOAD_DOCUMENT_STATE}
            this.spotLoadFormState.document.error = false;
        },
        handleDealerSelect(instance){
            if(instance && instance.locations.length > 0) {
                _.map(this.spotLoadFormState.orders, (item, index) => {
                    // if(!this.spotLoadFormState.orders[index].to_location_id) {
                        this.spotLoadFormState.orders[index].to_location_id = instance.locations[0].id;
                    // }
                })
            }
        },
        handleSupplierCompoundSelect(instance) {
            if(instance && instance.main_location) {
                _.map(this.spotLoadFormState.orders, (item, index) => {
                    // if(!this.spotLoadFormState.orders[index].to_location_id) {
                        this.spotLoadFormState.orders[index].to_location_id = instance.main_location.id;
                    // }
                })
            }
        }
    },
}
